.storyboard-image
  padding: 1.5rem
  
  .generation-progress
    display: flex
    flex-direction: column
    gap: 1rem

  .progress-bar
    width: 100%
    height: 1rem
    background-color: #f3f4f6
    border-radius: 9999px
    overflow: hidden
    
    &__fill
      height: 100%
      background-color: #2563eb
      transition: width 0.5s ease-out

  .generation-status
    display: flex
    align-items: center
    justify-content: center
    gap: 0.5rem
    
    &__text
      color: #4b5563

  .loading-spinner
    width: 1.25rem
    height: 1.25rem
    border: 2px solid #e5e7eb
    border-top-color: #2563eb
    border-radius: 50%
    animation: spin 1s linear infinite
    
    &--small
      width: 1rem
      height: 1rem
      margin-right: 0.5rem

  .image-container
    display: flex
    flex-direction: column
    gap: 1rem

  .image-wrapper
    border: 1px solid #e5e7eb
    border-radius: 0.5rem
    overflow: hidden

  .generated-image
    width: 100%
    height: auto
    display: block

  .image-selection
    margin-top: 1.5rem
    padding: 1rem
    background-color: #f9fafb
    border-radius: 0.5rem
    
    &__title
      font-size: 1.125rem
      font-weight: 600
      margin-bottom: 1rem
    
    &__options
      display: grid
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr))
      gap: 0.5rem

  .image-option
    display: flex
    align-items: center
    justify-content: center
    padding: 0.5rem
    background-color: white
    border: 1px solid #d1d5db
    border-radius: 0.375rem
    cursor: pointer
    transition: all 0.2s ease
    
    &:hover
      background-color: #f9fafb
    
    &--selected
      background-color: #ebf5ff
      border-color: #2563eb
      color: #1a56db
    
    &__input
      position: absolute
      opacity: 0
      width: 0
      height: 0
    
    &__label
      font-size: 0.875rem
      font-weight: 500

  .action-buttons
    margin-top: 1.5rem
    display: flex
    justify-content: flex-end
    gap: 0.75rem

  .button
    display: inline-flex
    align-items: center
    justify-content: center
    padding: 0.5rem 1rem
    font-size: 0.875rem
    font-weight: 500
    border-radius: 0.375rem
    transition: all 0.2s ease
    
    &:disabled
      opacity: 0.5
      cursor: not-allowed
    
    &--primary
      background-color: #2563eb
      color: white
      
      &:hover:not(:disabled)
        background-color: #1d4ed8
    
    &--secondary
      background-color: white
      border: 1px solid #d1d5db
      color: #374151
      
      &:hover:not(:disabled)
        background-color: #f9fafb
    
    &--loading
      cursor: not-allowed

@keyframes spin
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)

// Media Queries
@media (max-width: 640px)
  .storyboard-image
    padding: 1rem
    
    .image-selection__options
      grid-template-columns: repeat(2, 1fr)
    
    .action-buttons
      flex-direction: column
      gap: 0.5rem
      
      .button
        width: 100%
